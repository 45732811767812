import { useContext } from 'react';
import './chatSection.scss'
import ThemeContext from 'context/ThemeContext';

interface PropsType {
    isMe ?: boolean
    type ?: number
};
export default function LoadingItem({isMe, type}: PropsType) {
    const { theme } = useContext(ThemeContext)

    return (
        <div className="loadingItem">
            <div className={`item_content ${isMe ? 'row_reverse':''}`}>
                <div className={`avatar bg_${theme}`}></div>
                <div className="message_detail">
                    <div className="message_header">
                        <h4 className={`bg_${theme}`}></h4>
                        <span className={`time_div bg_${theme}`}></span>
                    </div>
                    {type === 1 &&
                    <div className="message_list">
                        <div className={`message_item first bg_${theme}`}>
                            <p className={`text_color_3_${theme}`}></p>
                        </div>
                        <div className={`message_item bg_${theme}`}>
                            <div className="img"></div>
                        </div>
                    </div>}
                    {type === 2 &&
                    <div className="message_list">
                        <div className={`message_item first bg_${theme}`}>
                            <p className={`text_color_3_${theme}`}></p>
                        </div>
                        <div className={`message_item bg_${theme}`}>
                            <p className={`text_color_3_${theme}`} style={{width : 250}}></p>
                        </div>
                    </div>}
                    {type === 3 &&
                    <div className="message_list">
                        <div className={`message_item first bg_${theme}`}>
                            <p className={`text_color_3_${theme}`}></p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
import { useContext } from 'react';
import moment from 'moment';
import './chatSection.scss'
import Avartar from 'components/Widgets/Avartar/Avartar';
import ThemeContext from 'context/ThemeContext';
import { truncateWalletString } from 'utils';
import { chatClient } from 'utils/WalletConnectUtil';

interface PropsType {
    chatThread?: any,
    chatUser?: any,
    onClick?: any
};
export default function ChatItem({ chatThread, chatUser, onClick }: PropsType) {
    const { theme } = useContext(ThemeContext)
    const messages = chatClient.getMessages({ topic: chatThread.topic })
    const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1] : null
    return (
        <div className={`chatItem border_${theme}`} onClick={onClick}>
            <div className="item_content">
                <Avartar img_url={chatUser?.lowLogo} account={chatUser?.address} size={'sm' || 'lg'}/>
                <div className="message_detail">
                    <h4 className={`text_color_2_${theme}`}>{chatUser?.name || (!chatUser?.ensName && chatUser?.ensName !== '' ? chatUser?.ensName : truncateWalletString(chatUser?.address))}</h4>
                    <p className={`text_color_gradient_2`}>{
                        lastMessage ? lastMessage.message.length > 25 ? lastMessage.message.substring(0, 25) + "..." : lastMessage.message : "No Messages"
                    }</p>
                </div>
                <div className="stat_div">
                    {/* <div className="unread_div">{3}</div>
                    <img src="/assets/icons/icon_message_read.svg" alt="" /> */}
                    {
                        lastMessage &&
                        <div className={`time_div text_color_4_${theme}`}>
                            {moment(lastMessage.timestamp).fromNow()}
                        </div>
                    }

                </div>
            </div>


        </div>
    )
}
import ReactDOM from 'react-dom';
import App from './App';
import Web3RainbowKitProvider from 'hooks/Web3RainbowKitProvider';
import ActiveWeb3Provider from 'hooks/useActiveWeb3';
import { ThemeProvider } from 'context/ThemeContext';
import { LoadingProvider } from 'context/useLoader';
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from 'context/UserContext';

ReactDOM.render(
  <Web3RainbowKitProvider>
    <ThemeProvider>
      <LoadingProvider>
        <ActiveWeb3Provider>
          <UserProvider>
            <App />
          </UserProvider>
        </ActiveWeb3Provider>
      </LoadingProvider>
    </ThemeProvider>
  </Web3RainbowKitProvider>,
  document.getElementById('root')
);

reportWebVitals();
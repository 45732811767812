import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu';
import Topbar from 'components/topbar/Topbar';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ThemeContext from 'context/ThemeContext';
import ChatSection from 'components/ChatSection/ChatSection';
export default function ChatPage() {
    const [isLoading, setIsLoading] = useState(true);
    const { theme } = useContext(ThemeContext)
    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "screen and (max-width: 640px) and (orientation:portrait)", });
    const isLandOrMobile = useMediaQuery({ query: "screen and (max-height: 640px) and (orientation:landscape)", });
    useEffect(() => {
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }

    }, [isLoading, isTabletOrMobile, isLandOrMobile]);
    window.onload = () => {
        setIsLoading(false)
    };

    return (
        <>
            <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Loading isLoading={isLoading} />
            <div className={`sections bg_1_${theme}`}>
                <ChatSection setIsLoading={setIsLoading} />
            </div>
        </>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './chatSection.scss'
import toast from 'react-hot-toast';
import { baseApiUrl, currentNetwork, truncateWalletString } from 'utils';
import ThemeContext from 'context/ThemeContext';
import Avartar from 'components/Widgets/Avartar/Avartar';
import ChatItem from './ChatItem';
import MessageItem from './MessageItem';
import { chatClient, createChatClient, createSignClient } from 'utils/WalletConnectUtil';
import { useEthersSigner } from 'hooks/wagmi-ethers';
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import UserItem from './UserItem';
import axios from 'axios';
import LoadingItem from './LoadingItem';
import { isAddress } from 'viem';
import UserContext from 'context/UserContext';


const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/

type LoadingType = {
  setIsLoading?(flag: boolean): void;
};
export default function ChatSection({ setIsLoading }: LoadingType) {
  const { theme } = useContext(ThemeContext)

  var slide_settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [showUser, setShowUser] = useState(false);
  const [tabId, setTabId] = useState('add_friend');
  const [showMessage, setShowMessage] = useState(false);

  const [searchMessage, setSearchMessage] = useState("");
  const handleKeyMessageSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchMessage(event.target.value);
    }
  }
  const [searchUser, setSearchUser] = useState("");
  const handleKeyUserSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchUser(event.target.value);
    }
  }
  const [message, setMessage] = useState("");
  const { user } = useContext(UserContext)
  const [msgUser, setMsgUser] = useState(undefined)

  const handleKeySendMessage = (event) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }
  const onClickShowUsers = () => {
    setTabId('all')
    setShowMessage(false)
  }
  const onClickUserItem = () => {
    setShowMessage(true)
  }
  const onClickTab = (tabId: string) => {
    setTabId(tabId)
  }

  //---------WalletConnect Chat-------------//

  const [messages, setMessages] = useState<
    {
      media?: {
        type: string;
        data: string;
      };
      message: string;
      topic: string;
      authorAccount: string;
      timestamp: number;
    }[]
  >([])

  const [chatAddrs, setChatAddrs] = useState([])
  const [chatUsers, setChatUsers] = useState([])

  const [chatThreads, setChatThreads] = useState<
    { topic: string; selfAccount: string; peerAccount: string }[]
  >([])
  const [activeTopic, setActiveTopic] = useState("")
  const [chatInvites, setChatInvites] = useState<any[]>([])
  const [chatSentInvites, setChatSentInvites] = useState<any[]>([])

  const { loginStatus, account, chainId, library, provider, connector } = useActiveWeb3()
  const [initialized, setInitialized] = useState(false)
  const [eip155Address, setEIP155Address] = useState("")
  const [otherAddr, setOtherAddr] = useState("")

  useEffect(() => {
    if (loginStatus && chainId && account) {
      setEIP155Address(`eip155:${chainId}:${account}`)
      
      const handleConnectorUpdate = ({ account, chain }) => {
        if (account) {
          window.location.reload()
        } else if (chain) {
          console.log("new chain", chain);
        }
      };
      if (connector) {
        connector.on("change", handleConnectorUpdate);
      }
      return () => connector?.off("change", handleConnectorUpdate);
    }
  }, [loginStatus, chainId, account])

  const onInitialize = async () => {
    try {
      setInitialized(true)
      await createSignClient()
      await createChatClient()
      await chatClient.register({
        account: eip155Address, onSign: async message => {
          return library?.signMessage(message);
        }
      });
      console.log('chatInvites on load:', chatClient.chatReceivedInvites.getAll({ inviteeAccount: eip155Address }))
      console.log('chatSentInvites on load:', chatClient.getSentInvites({ account: eip155Address }))
      console.log('chatThreads on load:', chatClient.chatThreads.getAll())
      console.log('chatMessages on load:', chatClient.chatMessages.getAll())

      const _chatThreads = chatClient.chatThreads.getAll()
      const peerAddrs = _chatThreads.map(_chatThread => _chatThread.peerAccount.split(":")[2].toLowerCase())
      setChatThreads(_chatThreads)

      const _chatInvites = chatClient.getReceivedInvites({ account: eip155Address })
      const inviterAddrs = _chatInvites.map(_chatInvite => _chatInvite.inviterAccount.split(":")[2].toLowerCase())
      setChatInvites(_chatInvites)

      const _chatSentInvites = chatClient.getSentInvites({ account: eip155Address })
      const inviteeAddrs = _chatSentInvites.map(_chatInvite => _chatInvite.inviteeAccount.split(":")[2].toLowerCase())
      setChatSentInvites(_chatSentInvites)

      setChatAddrs([...peerAddrs, ...inviterAddrs, ...inviteeAddrs])

      chatClient.on("chat_invite", async (event) => {
        // React to an incoming invite to chat.
        console.log('chat_invite:', event)
        updateChatInvites()
      });

      chatClient.on("chat_invite_accepted", async (event) => {
        // React to your peer joining a given chat.
        console.log('chat_joined:', event)
        updateChatInvites()
        updateChatThreads()
      });

      chatClient.on("chat_invite_rejected", async (event) => {
        // React to your peer declining your invite
        console.log('chat_rejected:', event)
        updateChatThreads()
      });
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (chatClient && activeTopic) {
      chatClient.on("chat_message", (event) => {
        // React to an incoming messages for a given chat.
        console.log("New Chat Message:", event)
        if (activeTopic && activeTopic.length > 0 && event.topic === activeTopic) {
          setMessages(chatClient.getMessages({ topic: event.topic }))
        }
      });
    }
    return () => {chatClient?.off("chat_message", (event) => {})};
  }, [activeTopic, chatClient])

  useEffect(() => {
    if (!initialized && eip155Address.length > 0) {
      onInitialize()
    }
  }, [initialized, onInitialize])

  const updateChatInvites = () => {
    const _chatInvites = chatClient.getReceivedInvites({ account: eip155Address })
    const inviterAddrs = _chatInvites.map(_chatInvite => _chatInvite.inviterAccount.split(":")[2].toLowerCase())
    setChatInvites(_chatInvites)
    setChatAddrs([...chatAddrs, ...inviterAddrs])
  }

  const updateChatThreads = () => {
    if (!chatClient) return
    const _chatThreads = chatClient.chatThreads.getAll()
    const peerAddrs = _chatThreads.map(_chatThread => _chatThread.peerAccount.split(":")[2].toLowerCase())
    setChatThreads(_chatThreads)
    setChatAddrs([...chatAddrs, ...peerAddrs])
  }

  const onAcceptInvite = async (event) => {
    if (chatClient && event && event.inviteeAccount === eip155Address && event.status === "pending") {
      try {
        await chatClient.accept({ id: Number(event.id) })
        updateChatInvites()
        updateChatThreads()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const onRejectInvite = async (event) => {
    if (chatClient) {
      try {
        await chatClient.reject({ id: event.id })
        updateChatInvites()
        updateChatThreads()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const sendInvite = async (_otherAddr) => {
    try {
      if (chatClient) {
        // const otherEIPAddr = `eip155:${chainId}:${_otherAddr}`
        if (!ADDRESS_REGEX.test(_otherAddr)) {
          if (ENS_NAME_REGEX.test(_otherAddr)) {
            _otherAddr = await provider.resolveName(_otherAddr)
          } else {
            return toast.error("Invalidation Error")
          }
        }
        const otherEIPAddr = `eip155:${chainId}:${_otherAddr}`
        const inviteePublicKey = await chatClient.resolve({ account: otherEIPAddr })
        await chatClient.invite({
          message: "Hey, Let's chat!",
          inviterAccount: `eip155:${chainId}:${account}`,
          inviteeAccount: otherEIPAddr, // the CAIP-2 formatted account of the recipient.
          inviteePublicKey
        });
        toast.success("The request has been sent successfully.")
      }
    } catch (e) {
      console.log(e)
      if (e.toString().includes("already invited")) toast.error("Already invited")
      else if (e.toString().includes("invite key found")) toast.error("The user is not registered into this platform")
    }
  }

  const onSelectChatThread = (_chatThread) => {
    setActiveTopic(_chatThread.topic)
    if (chatUsers && chatUsers.length > 0) {
      const _user = chatUsers.find(_chatUser => _chatUser.address.toLowerCase() === _chatThread.peerAccount.split(":")[2].toLowerCase())
      setMsgUser(_user)
    }
    setShowMessage(true)
    setMessages(chatClient.getMessages({ topic: _chatThread.topic }))
  }

  const sendMessage = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }

    if (!activeTopic || activeTopic.length <= 0) return;

    try {
      await chatClient.message({
        topic: activeTopic,
        message: message,
        authorAccount: eip155Address, // your CAIP-2 formatted account that you registered previously.
        timestamp: Date.now(),
      });

      if (chatClient.getMessages({ topic: activeTopic }).length) {
        setMessages(chatClient.getMessages({ topic: activeTopic }))
      }
      setMessage('')
    } catch (error) {
      console.log(error)
      toast.error("seding Failed!");
    }
  };

  useEffect(() => {
    if (chatAddrs && chatAddrs.length > 0) {
      const _chatAddrs = Array.from(new Set(chatAddrs))
      axios.get(`${baseApiUrl}/chat_users`, {
        params: {
          addresses: _chatAddrs
        }
      }).then(res => {
        if (res.data.status) {
          setChatUsers(res.data.users)
        }
      })
        .catch(err => {
          console.log(err)
        })
    }
  }, [chatAddrs])

  const onCopyAddress = (_address) => {
    navigator.clipboard.writeText(_address)
      .then(() => {
        toast.success("Copied to Clipboard")
      }).catch(err => {
        console.log(err)
      })
  }

  //--------------WalletConnect Chat End---------------//

  return (
    <div className="chatSection">
      <div className="content">
        {
          initialized &&
          <div className="wrapper">
            <div className={`left bg_${theme} ${showUser ? 'active' : ''}`}>
              <div className="left_content">
                <button className={`close_btn text_color_2_${theme}`} onClick={() => setShowUser(false)}>
                  <i className="fas fa-times"></i>
                </button>

                {/* <div className="avatar_list">
                  <Slider {...slide_settings}>
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                    <Avartar img_url='/assets/profile.png' border />
                  </Slider>
                </div> */}
                <div className={`line_${theme}`}></div>

                <div className="header">
                  {/* <h2 className={`text_color_2_${theme}`}>Messages <span className={`text_color_gradient_1`}>48 New</span></h2> */}
                  <h2 className={`text_color_2_${theme}`}>Messages</h2>
                  {/* <button className='icon_btn'><img src="/assets/icons/icon_message_edit.svg" alt="" className={theme} /></button> */}
                </div>

                <div className={`search_div bg_${theme} `}>
                  <input
                    type="text"
                    className={`text_color_3_${theme}`}
                    placeholder='Search anything...'
                    onChange={e => setSearchMessage(e.target.value)}
                    value={searchMessage}
                    onKeyPress={handleKeyMessageSearch}
                  />
                  <button className='icon_btn'><img src="/assets/icons/icon_search.svg" alt="" className={theme} /></button>
                </div>
                <div className="chatItem_list">
                  {
                    chatThreads && chatThreads.length > 0 && chatUsers &&
                    chatThreads.map((_chatThread, idx) => (
                      <ChatItem
                        onClick={() => onSelectChatThread(_chatThread)}
                        key={idx}
                        chatThread={_chatThread}
                        chatUser={chatUsers && chatUsers.find(_chatUser => _chatUser.address.toLowerCase() === _chatThread.peerAccount.split(":")[2].toLowerCase())}
                      />
                    ))
                  }

                  {/* <div className="header_label">
                    <h3><img src="/assets/icons/icon_bell.svg" alt="" /> Unread Messages</h3>
                  </div>

                  <div className="header_label">
                    <h3><img src="/assets/icons/icon_message_unread.svg" alt="" /> All Messages</h3>
                  </div> */}
                </div>
              </div>

            </div>
            <div className={`right border_${theme}`}>
              <div className="right_content">
                {showMessage ?
                  <div className={`right_header bg_${theme}`}>
                    {
                      msgUser && <>
                        <button className={`show_btn text_color_2_${theme}`} onClick={() => setShowUser(true)}>
                          <i className="fas fa-bars"></i>
                        </button>
                        <Avartar img_url={msgUser?.lowLogo} account={msgUser?.address} size='md' />
                        <div className="user_info">
                          <h4 className={`text_color_2_${theme}`}>{msgUser?.name === "NoName" ? msgUser?.ensName || "NoName" : msgUser?.name}</h4>
                          <p className={`text_color_gradient_2`} onClick={() => onCopyAddress(msgUser?.address)}>{truncateWalletString(msgUser?.address)}</p>
                        </div>

                        <div className="user_search">
                          <div className={`search bg_${theme}`}>
                            <input
                              type="text"
                              className={`text_color_3_${theme}`}
                              placeholder='Search anything...'
                              onChange={e => setSearchMessage(e.target.value)}
                              value={searchUser}
                              onKeyPress={handleKeyUserSearch}
                            />
                          </div>
                          <button className='icon_btn'><img src="/assets/icons/icon_search.svg" alt="" className={theme} /></button>
                          <div className="show_users_div">
                            <button className={`${theme}`} onClick={onClickShowUsers}><i className="fas fa-user-friends"></i> Friends</button>
                          </div>
                        </div>
                      </>
                    }

                  </div> :
                  <div className={`right_header bg_${theme}`}>
                    <button className={`show_btn text_color_2_${theme}`} onClick={() => setShowUser(true)}>
                      <i className="fas fa-bars"></i>
                    </button>
                    <div className="tab_btns">
                      <button className={`${theme} ${tabId === 'all' ? 'activeBtn' : ''}`} onClick={() => onClickTab('all')}>All</button>
                      <button className={`${theme} ${tabId === 'pending' ? 'activeBtn' : ''}`} onClick={() => onClickTab('pending')}>Pending</button>
                      <button className={`${theme} ${tabId === 'blocked' ? 'activeBtn' : ''}`} onClick={() => onClickTab('blocked')}>Blocked</button>
                      <button className={`${theme} addBtn ${tabId === 'add_friend' ? 'activeBtn' : ''}`} onClick={() => onClickTab('add_friend')}>Add Friend</button>
                    </div>
                    <div className="user_search">
                      <div className={`search bg_${theme}`}>
                        <input
                          type="text"
                          className={`text_color_3_${theme}`}
                          placeholder='Search anything...'
                          onChange={e => setSearchMessage(e.target.value)}
                          value={searchUser}
                          onKeyPress={handleKeyUserSearch}
                        />
                      </div>
                      <button className='icon_btn'><img src="/assets/icons/icon_search.svg" alt="" className={theme} /></button>
                    </div>
                  </div>
                }

                {!initialized ?
                  // -------------- When chat data is loading
                  <div className="tab_content">
                    <div className="messages_div">
                      <LoadingItem type={1} />
                      <LoadingItem type={2} />
                      <LoadingItem type={3} isMe />
                      <LoadingItem type={2} />
                      <LoadingItem type={2} isMe />
                    </div>
                  </div> :
                  // -------------- When chat data is loadied
                  <>
                    {!showMessage && chatInvites && chatUsers && chatUsers.length > 0 &&
                      chatInvites.filter((_chatInvite) => tabId === 'all' ? true : _chatInvite.status === (tabId === 'pending' ? 'pending' : 'rejected'))
                        .map((_chatInvite, idx) => (
                          <div className="tab_content" key={idx}>
                            <UserItem
                              status={tabId}
                              chatUser={chatUsers && chatUsers.find(_chatUser => _chatUser.address.toLowerCase() === _chatInvite.inviterAccount.split(":")[2].toLowerCase())}
                              chatInvite={_chatInvite}
                              onAccept={() => onAcceptInvite(_chatInvite)}
                              onReject={() => onRejectInvite(_chatInvite)}
                              onClick={onClickUserItem}
                            />
                          </div>
                        ))
                    }
                    {tabId === 'add_friend' && !showMessage &&
                      <div className="tab_content">
                        <h2 className={`sub_title text_color_2_${theme}`}>Add Friend</h2>
                        <p className={`sub_text text_color_4_${theme}`}>You can add friend with his ens name or address</p>
                        <div className={`send_invide_div ${theme}`}>
                          <input className={`text_color_3_${theme}`} type="text" onChange={e => setOtherAddr(e.target.value)} value={otherAddr} placeholder='You can add friend with his ens name or address' />
                          <button className={`${theme}`} onClick={() => sendInvite(otherAddr)}>Send Friend Request</button>
                        </div>
                      </div>
                    }
                    {showMessage &&
                      <div className="tab_content">
                        <div className="messages_div">
                          {
                            messages && chatUsers && chatUsers.length > 0 &&
                            messages.map((_message, idx) => (
                              <MessageItem
                                key={idx}
                                message={_message}
                                chatUser={chatUsers && chatUsers.find(_chatUser => _chatUser.address.toLowerCase() === _message.authorAccount.split(":")[2].toLowerCase())}
                                isMe={_message.authorAccount.split(":")[2].toLowerCase() === account.toLowerCase()}
                              />
                            ))
                          }

                          {/* <MessageItem
                            avatar_url='/assets/profile.png'
                            name='Marketplace Activity'
                            messages={[
                              { type: 'text', message: 'Please!!!!!!!!!!!!!!!!!!!!!!!!' },
                            ]}
                            time={1690366000}
                          />
                          <MessageItem
                            avatar_url='/assets/profile.png'
                            name='Marketplace Activity'
                            messages={[
                              { type: 'text', message: 'Allow me to make you an offer you can’t refuse.' },
                              { type: 'image', img: '/assets/logo_white.png' }
                            ]}
                            time={1690366000}
                          />
                          <MessageItem
                            avatar_url='/assets/profile.png'
                            name='Marketplace Activity'
                            messages={[
                              { type: 'text', message: 'Can i buy your NFT please?' },
                              { type: 'text', message: 'Allow me to make you an offer you can’t refuse.' }
                            ]}
                            time={1690366000}
                          />
                          <MessageItem
                            avatar_url='/assets/profile.png'
                            name='Marketplace Activity'
                            messages={[
                              { type: 'text', message: 'Can i buy your NFT please?' },
                              { type: 'text', message: 'Allow me to make you an offer you can’t refuse.' }
                            ]}
                            time={1690366000}
                          />
                          <MessageItem
                            avatar_url='/assets/profile.png'
                            name='You'
                            messages={[
                              { type: 'text', message: 'Never Selling!' },
                            ]}
                            isMe
                            time={1690366000}
                          /> */}
                        </div>
                        <div className="input_div">
                          <Avartar img_url={user?.lowLogo} account={user?.account} size='sm' />
                          <div className={`input ${theme}`}>
                            <div className="search">
                              <input
                                type="text"
                                className={`text_color_3_${theme}`}
                                placeholder='Type something...'
                                onChange={e => setMessage(e.target.value)}
                                value={message}
                                onKeyPress={handleKeySendMessage}
                              />
                            </div>
                            <div className="btns_div">
                              {/* <button className='icon_btn'><img src="/assets/icons/icon_pin.svg" alt="" className={theme} /></button>
                              <button className='icon_btn'><img src="/assets/icons/icon_mic.svg" alt="" className={theme} /></button> */}
                              <button className='icon_btn' onClick={sendMessage}>{theme === 'dark' ?
                                <img src="/assets/icons/icon_send.png" alt="" className={theme} /> :
                                <img src="/assets/icons/icon_send_light.png" alt="" className={theme} />}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { pulsechain } from 'wagmi/chains'

export const injectedConnector = new InjectedConnector({
  chains: [pulsechain],
  options: {
    // shimDisconnect: true,
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains: [pulsechain],
  options: {
    projectId: process.env.REACT_APP_PROJECT_ID,
  },
})

export const coinbaseWalletConnector = new CoinbaseWalletConnector({
  chains: [pulsechain],
  options: {
    appName: 'Hex Toys Chat'
  },
})

export function getConnector(connectorId) {
  switch (connectorId) {
    case "injectedConnector":
      return injectedConnector;
    case "walletconnect":
      return walletConnectConnector;
    case "coinbase":
        return coinbaseWalletConnector;
    default:
      return injectedConnector;
  }
}

export const connectorLocalStorageKey = "connectorId";
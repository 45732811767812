import { useContext } from 'react';
import moment from 'moment';
import './chatSection.scss'
import Avartar from 'components/Widgets/Avartar/Avartar';
import ThemeContext from 'context/ThemeContext';
import { truncateWalletString } from 'utils';
import UserContext from 'context/UserContext';

interface PropsType {
    message?: any,
    chatUser?: any,
    isMe?: boolean
};
export default function MessageItem({ message, chatUser, isMe }: PropsType) {
    const { theme } = useContext(ThemeContext)
    const { user } = useContext(UserContext)
    return (
        <div className="messageItem">
            <div className={`item_content ${isMe ? 'row_reverse' : ''}`}>
                <Avartar img_url={isMe ? user?.lowLogo : chatUser?.lowLogo} account={isMe? user?.address : chatUser?.address} size={'sm' || 'lg'} />
                <div className="message_detail">
                    <div className="message_header">
                        {/* <h4 className={`text_color_2_${theme}`}>{chatUser?.name || (!chatUser?.ensName && chatUser?.ensName !== '' ? chatUser?.ensName : truncateWalletString(chatUser?.address))}</h4> */}
                        <span className={`time_div text_color_4_${theme}`}>
                            {moment(message.timestamp).fromNow()}
                        </span>
                    </div>

                    <div className="message_list">
                        {/* {messages?.map((item, k)=>{
                            return(
                                    item?.type === 'text' ?
                                        <div className={`message_item ${k === 0 ? 'first':''} bg_${theme}`} key = {k}>
                                            <p className={`text_color_3_${theme}`}>{item?.message}</p>
                                        </div>:
                                    item?.type === 'image' ?
                                        <div className={`message_item ${k === 0 ? 'first':''} bg_${theme}`} key = {k}>
                                            <img src={item?.img} alt="" />
                                        </div>:
                                        <></>
                            )
                        }
                            
                        )} */}
                        {
                            !message?.media ?
                                <div className={`message_item ${'first' || ''} bg_${theme}`}>
                                    <p className={`text_color_3_${theme}`}>{message?.message}</p>
                                </div> :
                                <div className={`message_item ${'first' || ''} bg_${theme}`}>
                                    <img src={message?.data} alt="" />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
import axios from "axios"
import { useActiveWeb3 } from "hooks/useActiveWeb3"
import { useState, createContext, useEffect } from "react"

const UserContext = createContext({
  user: null,
  setUser: (val) => { },
})

export default UserContext

export function UserProvider(props) {
  const [user, setUser] = useState(null)
  const value = { user, setUser }
  const { loginStatus, account } = useActiveWeb3()

  useEffect(() => {
    if (loginStatus && account) {
      fetchUser()
    }
  }, [loginStatus, account])

  const fetchUser = async () => {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/user_info/${account.toLowerCase()}`);
    if (res.data.status) {
      setUser(res.data.user)
    }
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}
import { useContext, useState } from 'react';
import './chatSection.scss'
import Avartar from 'components/Widgets/Avartar/Avartar';
import ThemeContext from 'context/ThemeContext';
import { truncateWalletString } from 'utils';

interface PropsType {
    chatUser?: any,
    chatInvite?: any,
    status?: string, //all, pending, blocked

    onClick?: any
    onAccept?: any
    onReject?: any
};
export default function UserItem({ chatUser, chatInvite, status, onClick, onAccept, onReject }: PropsType) {
    const { theme } = useContext(ThemeContext)

    return (
        <div className={`userItem border_${theme}`}>
            <div className="item_content">
                <Avartar img_url={chatUser?.lowLogo} account={chatUser?.address} onClick={onClick} />
                <div className="message_detail" onClick={onClick}>
                    <h4 className={`text_color_2_${theme}`}>{chatUser?.name || (!chatUser?.ensName && chatUser?.ensName !== '' ? chatUser?.ensName : truncateWalletString(chatUser?.address))}</h4>
                    {chatInvite?.status === "pending" && <p className={`text_color_4_${theme}`}>{chatInvite?.message}</p>}
                </div>
                <div className="btns_div">
                    {chatInvite?.status === "pending" &&
                        <>
                            <button className={`${theme}`} onClick={onAccept}><i className="fas fa-check"></i></button>
                            <button className={`${theme} red`} onClick={onReject}><i className="fas fa-times"></i></button>
                        </>
                    }
                    {chatInvite?.status === "rejected" &&
                        <>
                            <button className={`${theme}`} onClick={onAccept}><i className="fas fa-user-times"></i></button>
                        </>
                    }
                    {chatInvite?.status === "approved" &&
                        <>
                            <button className={`${theme}`} onClick={onClick}><i className="fas fa-comment-alt"></i></button>
                        </>
                    }
                </div>
            </div>


        </div>
    )
}